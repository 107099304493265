<section class="mt-5">
  <backstage-layout-grid [gap]="4">
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        [href]="('serviceCloud' | env) + '/s/' | serviceCloudSsoUrl">
        <backstage-icon
          size="4rem"
          iconId="backstage/support"
          class="d-block grayscale"/>
        Support
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        href="/downloads">
        <backstage-icon
          size="4rem"
          iconId="backstage/downloads"
          class="d-block grayscale"/>
        Downloads
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        href="/docs">
        <backstage-icon
          size="4rem"
          iconId="backstage/docs"
          class="d-block grayscale"/>
        Docs
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        [href]="('serviceCloud' | env) + '/s/knowledge-base' | serviceCloudSsoUrl">
        <backstage-icon
          size="4rem"
          iconId="backstage/knowledge"
          class="d-block grayscale"/>
        Knowledge
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        href="/university">
        <backstage-icon
          size="4rem"
          iconId="backstage/university"
          class="d-block grayscale"/>
        Training
      </a>
    </backstage-layout-grid-cell>
    @if ([ 'customer', 'contractor', 'internal' ] | hasRole) {
      <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
        <a
          class="w-100 btn btn-lg btn-white btn-block pt-3"
          href="/cloudstorage">
          <backstage-icon
            size="4rem"
            iconId="open-standards"
            class="d-block grayscale"/>
          Cloud Storage
        </a>
      </backstage-layout-grid-cell>
    }
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        href="/marketplace">
        <backstage-icon
          size="4rem"
          iconId="backstage/marketplace"
          class="d-block grayscale"/>
        Marketplace
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        [href]="('serviceCloud' | env) + '/s/community-home' | serviceCloudSsoUrl">
        <backstage-icon
          size="4rem"
          iconId="backstage/forums"
          class="d-block grayscale"/>
        Community
      </a>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs:6, md: 3}">
      <a
        class="w-100 btn btn-lg btn-white btn-block pt-3"
        href="/community/developer">
        <backstage-icon
          size="4rem"
          iconId="backstage/source-code"
          class="d-block grayscale"/>
        Developers
      </a>
    </backstage-layout-grid-cell>
  </backstage-layout-grid>
</section>
