<div class="card bg-white-alpha-50 mt-4" [@panelState]="loaded ? 'in' : 'out'">
  <div class="card-body pb-0">
    <h3 i18n class="fs-1 mb-3">
      <backstage-icon iconId="backstage/knowledge" class="grayscale" size="2.5rem"></backstage-icon>
      Knowledge
    </h3>
    <div class="row ps-3">
      <div *ngFor="let library of libraries" class="col-md-4 mb-3 ps-0">
        <app-card className="bg-white border shadow-sm">
          <main>
            <h5 class="card-title text-dark fw-bold">{{library.displayName}}</h5>
            <p class="mb-0">{{library.description}}</p>
          </main>
          <footer class="d-flex">
            <a class="ms-auto btn btn-ping-red stretched-link" href="/knowledge/{{library.id}}">
              <i class="bi-arrow-right-circle-fill"></i>
              <ng-container i18n> Browse</ng-container>
            </a>
          </footer>
        </app-card>
      </div>
    </div>
    <div class="row" *ngIf="data?.results?.length">
      <div class="col-md">
        <hr>
        <h4 class="mb-3">Featured Articles</h4>
        <div class="row ps-3">
          <div *ngFor="let result of data?.results" class="col-md-6 mb-3 ps-0">
            <app-card className="bg-gray-200" headerClassName="bg-gray-300">
              <header>
                <a [href]="result.url">{{result.title}}</a>
              </header>
              <main>
                <backstage-ellipsis [length]="150" [content]="result. synopsis || ''"></backstage-ellipsis>
              </main>
              <footer class="d-flex align-items-center">
                <div class="badge bg-dark me-1">{{result.publicationType}}</div>
                <a class="ms-auto btn btn-ping-red stretched-link" [href]="result.url">
                  <i class="bi-arrow-right-circle-fill"></i>
                  <ng-container i18n> Read</ng-container>
                </a>
              </footer>
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
