<app-card>
  <header>{{org.name}}</header>
  <ul class="list-group list-group-flush">
    <li class="list-group-item bg-gray-200" *ngFor="let team of org.teams">
      <a href="/account/subscriptions/{{team.supportKey}}" [class.strong]="(team.assets | productFamilies).length"
         class="text-ping-red fw-bold">{{team.supportKey}}</a>
      <div>{{team.description}}</div>
      <div class="mt-1">
        <app-product-label *ngFor="let productFamily of (team.assets | productFamilies).sort()"
                           [productId]="productFamily" class="d-inline-block me-1"></app-product-label>
      </div>
    </li>
  </ul>
</app-card>
