import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ErrorCode } from '../model/error-code';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.scss' ]
})
export class ErrorComponent implements OnInit {
  ErrorCode = ErrorCode;
  code: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.code = this.route.snapshot.params.code;
  }

}
