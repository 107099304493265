<header class="d-print-none">
  <backstage-site-header chatbot="true" wide="wide"></backstage-site-header>
</header>
<main>
  <backstage-notifications [connect]="true"></backstage-notifications>
  <router-outlet></router-outlet>
</main>
<footer class="d-print-none">
  <backstage-site-footer wide="wide"></backstage-site-footer>
</footer>
