import { Component, Input, OnInit } from '@angular/core';

import { productDisplayName, productClassName, productName } from '../model/product-name';

@Component({
  selector: 'app-product-label',
  templateUrl: './product-label.component.html',
  styleUrls: [ './product-label.component.scss' ]
})
export class ProductLabelComponent implements OnInit {
  @Input() productId: string;
  productName: string;
  className: string;
  tooltip: string;

  ngOnInit() {
    this.productName = productName[ this.productId ] || productName.unknown;
    this.className = this.getClassName(this.productId);
    this.tooltip = productDisplayName[ this.productId ] || this.productId;
  }

  getClassName(productId: string): string {
    return productName[ productId ]
      ? `${productClassName[ productId ] ?? ''} bg-dark`
      : 'bg-gray-500';
  }
}
