import { Injectable } from '@angular/core';
import { BackendService, comparator } from '@forgerock/backstage-ui-core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Library } from './model/library';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  url = '/knowledge-ws/api/v1/library';
  private libraries: Promise<Library[]>;

  constructor(private backend: BackendService) {}

  getLibraries(): Promise<Library[]> {
    if (!this.libraries) {
      this.libraries = firstValueFrom(
        this.backend.get(this.url).pipe(
          map((libraries: Library[]) => libraries
            .filter(library => !library.abandoned && !library.hidden)
            .sort(comparator<Library>('displayName', true))
          )
        )
      );
    }

    return this.libraries;
  }

}
