<div
  class="card bg-white-alpha-50 mt-4"
  [@panelState]="('community' | hasRole) ? 'in' : 'out'">
  <div class="card-body">
    <h3
      i18n
      class="fs-1 mb-3">
      <backstage-icon
        iconId="backstage/support"
        class="grayscale"
        size="2.5rem"></backstage-icon>
      Support
    </h3>
    <p
      class="mb-4 lead text-dark"
      i18n>
      Our biggest ambition is to help customers achieve theirs. Whether you’re kicking off a small identity project or
      rolling out an enterprise-wide deployment, ForgeRock offers products and services to help you achieve even your
      loftiest goals. We measure our outline-success with customer outline-success — not billable hours.
    </p>
    <div>
      <hr>
      <h4
        class="mb-3"
        library
        i18n>Subscriptions</h4>
      <div
        class="text-nowrap overflow-scroll"
        [@panelState]="(orgs | async)?.length ? 'in' : 'out'">
        <div
          class="col-md-5 d-inline-block mb-3 ps-0 text-wrap me-3"
          *ngFor="let org of orgs | async">
          <app-org-card [org]="org"></app-org-card>
        </div>
      </div>
      <div>
        Don't see your subscription listed here?
        <a
          href="/account/subscriptions#join"
          class="text-ping-red">Join a subscription <i class="bi-arrow-up-right-square"></i></a>
      </div>
    </div>
    <hr>
    <div [@panelState]="('customer' | hasRole) ? 'in' : 'out'">
      <h4
        class="mb-3"
        i18n>Resources</h4>
      <div class="row ps-3">
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/account/subscriptions"
            class="btn btn-ping-gray btn-lg">
            <i class="bi-building-fill"></i><br>
            <ng-container i18n>Subscriptions</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            [href]="('serviceCloud' | env) + '/s/' | serviceCloudSsoUrl"
            class="btn btn-ping-gray btn-lg">
            <i class="bi-tags-fill"></i><br>
            <ng-container i18n>Tickets</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/search/?t=issues"
            class="btn btn-ping-gray btn-lg">
            <i class="bi-bug-fill"></i><br>
            <ng-container i18n>Issue Tracking</ng-container>
          </a>
        </div>
        <div class="col-lg-3 ps-0 mb-3 d-grid">
          <a
            href="/cloudstorage/subscriptions?tag=customer-patch"
            class="btn btn-ping-gray btn-lg">
            <i class="bi-patch-check-fill"></i><br>
            <ng-container i18n>Patches</ng-container>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
