import { Injectable } from '@angular/core';
import { AbstractSearchService } from '@forgerock/backstage-ui-core';
import { SearchResult } from './model/search-result';

@Injectable({
  providedIn: 'root'
})
export class EverythingSearchService extends AbstractSearchService<SearchResult> {
  url = '/search-ws/api/v1/everything/_search';
}
