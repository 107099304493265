import { Injectable } from '@angular/core';
import { AccountService, BackendService, RoleName, RoleService } from '@forgerock/backstage-ui-core';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  url = '/users-ws/api/v1/user';

  constructor(private backend: BackendService,
              private accountService: AccountService,
              private roleService: RoleService) { }

  isEmailUnverified(): Observable<boolean> {
    return this.roleService.hasRole(RoleName.community).pipe(
      filter(hasRole => hasRole),
      switchMap(() => this.roleService.hasRole(RoleName.emailVerified)),
      map(res => !res)
    );
  }

  getEmailVerifiedUntil(): Observable<Date> {
    return this.accountService.me().pipe(
      map(account => {
        const verifiedUntil = new Date(account.emailVerified);
        verifiedUntil.setFullYear(verifiedUntil.getFullYear() + 1);
        return account.emailVerified ? verifiedUntil : null;
      })
    );
  }

  sendVerificationEmail(): Promise<void> {
    return firstValueFrom(
      this.backend.post(`${this.url}/_me/verifyemail/_resend`)
    );
  }


}
