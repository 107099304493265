import { Component, Input } from '@angular/core';
import { SearchResult } from '../model/search-result';

@Component({
  selector: 'app-dashboard-marketplace-panel-item',
  templateUrl: './dashboard-marketplace-panel-item.component.html',
  styleUrls: [ './dashboard-marketplace-panel-item.component.scss' ]
})
export class DashboardMarketplacePanelItemComponent {
  @Input() result: SearchResult;
  fallbackThumbnailUrl = 'https://cdn.forgerock.com/icons/backstage/marketplace.svg';
  error: boolean;
}
