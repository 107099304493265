export interface LearningPass {
  id: string;
  tier: LearningPassTier;
  validFrom: string;
  validUntil: string;
  status: LearningPassStatus;
}

export enum LearningPassTier {
  BASIC_ACCESS = 'BASIC_ACCESS',
  PRO_ACCESS = 'PRO_ACCESS',
  ALL_ACCESS = 'ALL_ACCESS'
}

export enum LearningPassStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  INACTIVE = 'INACTIVE'
}
