/* eslint-disable @typescript-eslint/naming-convention */

export enum ErrorCode {
  session_error = 'session_error',
  data_error = 'data_error',
  forbidden = 'forbidden',
  not_found = 'not_found',
  email_not_verified = 'email_not_verified',
  subscription_invalid = 'subscription_invalid',
  deleted = 'deleted'
}
