<div [@panelState]="(isAllowed | async) ? 'in' : 'out'">
  <div class="container pe-0">
    <h3 class="fw-bold text-center mb-0">Backstage Admin</h3>
    <div class="d-flex flex-wrap justify-content-between pt-3">
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="['user-admin', 'user-vice-admin'] | hasRole">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap shadow-sm" href="/admin/users">
          <i class="bi-people"></i>&nbsp;
          <ng-container i18n>Users</ng-container>
        </a>
      </div>
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="['subscription-admin', 'subscription-viewer'] | hasRole">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap" href="/admin/subscriptions">
          <i class="bi-building "></i>&nbsp;
          <ng-container i18n>Subscriptions</ng-container>
        </a>
      </div>
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="'notification-admin' | hasRole">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap" href="/admin/notifications">
          <i class="bi-bell"></i>&nbsp;
          <ng-container i18n>Notifications</ng-container>
        </a>
      </div>
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="'user-admin' | hasRole">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap" href="/admin/api-keys">
          <i class="bi-key"></i>&nbsp;
          <ng-container i18n>API Keys</ng-container>
        </a>
      </div>
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="isKbUser | async">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap" href="/publisher">
          <i class="bi-pen"></i>&nbsp;
          <ng-container i18n>Publisher</ng-container>
        </a>
      </div>
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="'kibana-admin' | hasRole">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap" href="/reporting/app/kibana#/dashboards">
          <i class="bi-bar-chart"></i>&nbsp;
          <ng-container i18n>Reporting</ng-container>
        </a>
      </div>
      <div class="mb-3 d-grid flex-grow-1 me-3" *ngIf="['release-manager', 'release-editor'] | hasRole">
        <a class="btn btn-lg btn-ping-red text-white shadow-sm text-nowrap" href="/product-releases">
          <i class="bi-rocket"></i>&nbsp;
          <ng-container i18n>Product Releases</ng-container>
        </a>
      </div>
    </div>
  </div>
</div>

