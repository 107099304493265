import { Injectable } from '@angular/core';
import { AccountService, BackendService, EnvService, SessionService } from '@forgerock/backstage-ui-core';
import { firstValueFrom } from 'rxjs';
import { DashboardEnv } from './model/dashboard-env';
import { LearningPass, LearningPassStatus, LearningPassTier } from './model/learning-pass';

@Injectable({
  providedIn: 'root'
})
export class UniversityService {
  url = '/university-ws/api/v1/learningpass/_me';
  learningPasses: Promise<LearningPass[]>;
  activeLearningPassTiers: Promise<LearningPassTier[]>;

  constructor(private backend: BackendService,
              private accountService: AccountService,
              private envService: EnvService<DashboardEnv>,
              private sessionService: SessionService) {
  }

  async getLearningPasses(): Promise<LearningPass[]> {
    return (await this.sessionService.isLoggedIn())
      ? firstValueFrom(this.backend.get(this.url))
      : [];
  }

  async getActiveLearningPassTiers(): Promise<LearningPassTier[]> {
    if (!this.activeLearningPassTiers) {
      this.activeLearningPassTiers = this.getLearningPasses().then(all => all
        .filter(pass => pass.status === LearningPassStatus.ACTIVE)
        .map(pass => pass.tier)
      );
    }
    return this.activeLearningPassTiers;
  }

  /**
   * Attempts to get the 'best' (i.e. most access) learning pass for a user
   */
  async getBestActiveLearningPass(): Promise<LearningPassTier> {
    const tiers = await this.getActiveLearningPassTiers();
    // All Access is better than Pro Access
    if (tiers.includes(LearningPassTier.ALL_ACCESS)) {
      return LearningPassTier.ALL_ACCESS;
    } else if (tiers.includes(LearningPassTier.PRO_ACCESS)) {
      return LearningPassTier.PRO_ACCESS;
    } else {
      return LearningPassTier.BASIC_ACCESS;
    }
  }
}
