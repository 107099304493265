/* eslint-disable @typescript-eslint/naming-convention */

export const productName = {
  openam: 'AM',
  opendj: 'DS',
  openidm: 'IDM',
  openig: 'IG',
  openicf: 'OpenICF',
  am: 'AM',
  ds: 'DS',
  idm: 'IDM',
  ig: 'IG',
  cloud: ' ',
  open_banking: 'OB',
  unknown: '...'
};

export const productDisplayName = {
  openam: 'PingAM',
  opendj: 'PingDS',
  openidm: 'PingIDM',
  openig: 'PingGateway',
  cloud: 'PingOne Advanced Identity Cloud',
  open_banking: 'Open Banking Sandbox',
  'autonomous-iam': 'Autonomous Identity and Access'
};

export const productClassName = {
  cloud: 'bi-cloud-fill'
};
