<form
  name="searchForm"
  (submit)="redirectToSearchUi()">
  <div class="container">
    <div class="row my-3 justify-content-lg-center">
      <div class="col col-lg-8 ps-0 pe-2 d-flex">
        <div
          class="input-group"
          ngbDropdown>
          <button
            type="button"
            class="btn btn-gray-100 btn-lg btn-reset text-capitalize d-none d-sm-block z-index-10 fs-6 border-gray-500 rounded-0"
            ngbDropdownToggle>{{ searchTab }}
          </button>
          <div
            ngbDropdownMenu
            class="shadow">
            <button
              ngbDropdownItem
              type="button"
              class="text-capitalize"
              *ngFor="let tab of searchTabs"
              (click)="searchTab = tab">{{ tab }}
            </button>
          </div>
          <label
            for="query"
            class="visually-hidden">Query</label>
          <input
            id="query"
            type="text"
            autofocus
            tabindex="1"
            autocomplete="off"
            class="form-control form-control-lg bg-white fs-6 border-gray-500"
            placeholder="What can we help you find?"
            name="query"
            [(ngModel)]="query">
          <i
            class="bi bi-search position-absolute text-black-50"
            tabindex="2"></i>
        </div>
      </div>
    </div>
  </div>
</form>
