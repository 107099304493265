import { Component, OnInit } from '@angular/core';
import { EnvService } from '@forgerock/backstage-ui-core';
import { DashboardEnv } from '../model/dashboard-env';
import { PANEL_ANIMATION } from '../panel-animation';

@Component({
  selector: 'app-dashboard-maintenance-info-panel',
  templateUrl: './dashboard-maintenance-info-panel.component.html',
  styleUrls: [ './dashboard-maintenance-info-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ]
})
export class DashboardMaintenanceInfoPanelComponent implements OnInit {

  displayPanel = false;
  constructor(private envService: EnvService<DashboardEnv>) { }

  async ngOnInit() {
    this.displayPanel = !!(await this.envService.get()).maintenanceMode;
  }

}
