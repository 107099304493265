import { animate, state, style, transition, trigger } from '@angular/animations';

const outState = style({
  transform: 'translateY(10%)',
  opacity: 0,
  height: 0,
  padding: 0,
  margin: 0,
  overflow: 'hidden'
});

const inState = style({
  transform: 'translateY(0)',
  opacity: 1,
  overflow: 'hidden'
});

const animation = animate('500ms ease-in');

export const PANEL_ANIMATION = trigger('panelState', [
  state('in', inState),
  state('out', outState),
  transition('out => *', [ outState, animation ])
]);
