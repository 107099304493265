import { Component } from '@angular/core';
import { AccountService, RoleService } from '@forgerock/backstage-ui-core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PANEL_ANIMATION } from '../panel-animation';

@Component({
  selector: 'app-dashboard-admin-panel',
  templateUrl: './dashboard-admin-panel.component.html',
  styleUrls: [ './dashboard-admin-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ]
})
export class DashboardAdminPanelComponent {

  roles = [
    'subscription-admin',
    'subscription-viewer',
    'kibana-admin',
    'user-admin',
    'user-vice-admin',
    'notification-admin',
    'kibana-admin'
  ];

  kbRolePrefixes = [
    'kb-admin:',
    'kb-editor:',
    'kb-contributor:',
    'kb-reader'
  ];

  isAdmin: Observable<boolean> = this.roleService.hasAny(this.roles);

  /**
   * Emits true if the user has any kb-* role
   */
  isKbUser: Observable<boolean> = this.accountService.me().pipe(
    map(({ roles }) => {
      for (const rolePrefix of this.kbRolePrefixes) {
        for (const role of roles) {
          if (role.startsWith(rolePrefix)) {
            return true;
          }
        }
      }

      return false;
    })
  );

  /**
   * Emits true if the user has an admin or KB role
   */
  isAllowed: Observable<boolean> = combineLatest([ this.isAdmin, this.isKbUser ]).pipe(
    map(([ isAdmin, isKbUser ]) => isAdmin || isKbUser)
  );

  constructor(private accountService: AccountService,
              private roleService: RoleService) {}
}
