import { inject, Injectable } from '@angular/core';
import { EnvService } from '@forgerock/backstage-ui-core';
import { DashboardEnv } from './model/dashboard-env';

@Injectable({
  providedIn: 'root'
})
export class UrlGeneratorService {

  private envService: EnvService<DashboardEnv> = inject(EnvService);

  async getServiceCloudSsoUrl(target: string, loggedIn: boolean) {
    if (!loggedIn) {
      return target;
    }

    const { sso, serviceCloudEntityId } = await this.envService.get();
    const params = new URLSearchParams({
      metaAlias: '/community/idp3',
      spEntityID: serviceCloudEntityId,
      RelayState: target,
      binding: 'HTTP-POST'
    });
    return `${sso}/saml2/jsp/idpSSOInit.jsp?${params}`;
  }
}
