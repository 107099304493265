<div class="card bg-white-alpha-50 mt-4" [@panelState]="loaded ? 'in' : 'out'">
  <div class="card-body">
    <h3 i18n class="fs-1 mb-3">
      <backstage-icon iconId="backstage/marketplace" class="grayscale" size="2.5rem"></backstage-icon>
      Marketplace
    </h3>
    <p class="mb-3 lead text-dark" i18n>
      Check out what others are doing, get involved with other projects or build a community around
      your code by hosting your Git Repository on our ForgeRock GitHub account so others may find it. Note; these
      projects are not supported or tested by ForgeRock. These projects are not run by ForgeRock, but by the project
      maintainers.
    </p>
    <p class="mb-0 text-center">
      <a href="/marketplace" class="btn btn-outline-ping-gray btn-lg rounded-0"><i class="bi-arrow-right"></i> Browse the
        Marketplace</a>
    </p>
    <hr>
    <h4 class="mb-3">
      Featured Content
    </h4>
    <div class="overflow-scroll d-flex flex-row">
      <div class="col-md-5 mb-3 me-3 ps-0" *ngFor="let result of results">
        <app-dashboard-marketplace-panel-item [result]="result"/>
      </div>
    </div>
  </div>
</div>
