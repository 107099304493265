import { inject, Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@forgerock/backstage-ui-core';
import { UrlGeneratorService } from '../url-generator.service';

@Pipe({
  name: 'serviceCloudSsoUrl',
  pure: false
})
export class ServiceCloudSsoUrlPipe implements PipeTransform {

  private urlGeneratorService = inject(UrlGeneratorService);
  private sessionService: SessionService = inject(SessionService);

  private target: string = null;
  private value: string;

  transform(target: string): string {
    if (target && target !== this.target) {
      this.target = target;
      this.sessionService.isLoggedIn()
        .then(it => this.urlGeneratorService.getServiceCloudSsoUrl(target, it))
        .then(it => this.value = it);
    }

    return this.value;
  }

}
