<div class="alert alert-warning mt-4 shadow" [@panelState]="(isEmailUnverified | async) ? 'in' : 'out'">
  <h3 i18n>Verify Your Email</h3>
  <p>
    <ng-container *ngIf="emailVerifiedUntil | async">
      <ng-container i18n>Your email address verification expired on</ng-container>
      {{emailVerifiedUntil | async | date: 'mediumDate'}}.
    </ng-container>
    <ng-container *ngIf="(emailVerifiedUntil | async) === null" i18n>
      Your email address is currently unverified.
    </ng-container>
    <ng-container i18n>Access to support, downloads and other services is restricted.
      Please verify your email by requesting a new verification token and following the link in the email we send you.
    </ng-container>
  </p>
  <p class="m-0">
    <button class="btn btn-ping-red me-3" (click)="sendVerificationEmail()">
      <i class="bi-check-circle-fill"></i> <ng-container i18n> Verify now</ng-container>
    </button>
    <a class="btn btn-info" href="/account/profile">
      <i class="bi-arrow-right-circle-fill"></i> <ng-container i18n> Go to account settings</ng-container>
    </a>
  </p>
</div>
