import { Component, OnInit } from '@angular/core';
import { AlertService } from '@forgerock/backstage-ui-core';
import { Observable } from 'rxjs';
import { PANEL_ANIMATION } from '../panel-animation';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-dashboard-verify-email-panel',
  templateUrl: './dashboard-verify-email-panel.component.html',
  styleUrls: [ './dashboard-verify-email-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ]
})
export class DashboardVerifyEmailPanelComponent implements OnInit {
  isEmailUnverified: Observable<boolean>;
  emailVerifiedUntil: Observable<Date>;

  constructor(private userAccountService: UserAccountService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.isEmailUnverified = this.userAccountService.isEmailUnverified();
    this.emailVerifiedUntil = this.userAccountService.getEmailVerifiedUntil();
  }

  async sendVerificationEmail() {
    try {
      await this.userAccountService.sendVerificationEmail();
      this.alertService.success('A verification token has been sent to your email address');
    } catch (err) {
      this.alertService.error(err);
    }
  }

}
