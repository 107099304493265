import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function show(element: HTMLElement) {
  element.style.display = 'block';
}

function hide(element: HTMLElement) {
  element.style.display = 'none';
}

// handle old #! URLs
try {
  if (location.hash.startsWith('#!')) {
    location.replace(location.hash.replace(/^#!/, ''));
  }
} catch {
  // unexpected error - ignore and show the dashboard
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    hide(document.getElementById('waitforit'));
  })
  .catch(err => {
    hide(document.getElementById('app-loading'));
    show(document.getElementById('app-error'));
    console.error(err);
  });
