import { Component, OnInit } from '@angular/core';
import { PANEL_ANIMATION } from '../panel-animation';
import { UniversityService } from '../university.service';
import { LearningPassTier } from '../model/learning-pass';

@Component({
  selector: 'app-dashboard-university-panel',
  templateUrl: './dashboard-university-panel.component.html',
  styleUrls: [ './dashboard-university-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ]
})
export class DashboardUniversityPanelComponent implements OnInit{
  activeLearningPassTier: LearningPassTier;
  learningPassTier = LearningPassTier;

  constructor(private universityService: UniversityService) {}

  async ngOnInit() {
    this.activeLearningPassTier = await this.universityService.getBestActiveLearningPass();
  }

}
