import { Component, OnInit } from '@angular/core';
import { and, filter, SearchFilterName, SearchResults, SearchSortFieldName, value } from '@forgerock/backstage-ui-core';
import { Library } from '../model/library';
import { SearchResult } from '../model/search-result';
import { EverythingSearchService } from '../everything-search.service';
import { LibraryService } from '../library.service';
import { PANEL_ANIMATION } from '../panel-animation';

@Component({
  selector: 'app-dashboard-kb-panel',
  templateUrl: './dashboard-kb-panel.component.html',
  styleUrls: [ './dashboard-kb-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ]

})
export class DashboardKbPanelComponent implements OnInit {
  loaded: boolean;
  error: string;
  data: SearchResults<SearchResult>;
  libraries: Library[] = [];

  constructor(private searchService: EverythingSearchService,
              private libraryService: LibraryService) { }

  async ngOnInit() {
    this.searchService
      .withNestedFilter(
        and(
          value(filter(SearchFilterName.library, 'advisories')),
          value(filter(SearchFilterName.majorUpdate, true))
        )
      )
      .withSort(SearchSortFieldName.updateTimestamp, false)
      .withCount(6);

    try {
      this.libraries = await this.libraryService.getLibraries();
      this.data = await this.searchService.getPageAsync();
      this.loaded = true;
    } catch {
      // ignore
    }
  }
}
