import { Component, OnInit } from '@angular/core';
import { SearchDocumentType, SearchSortFieldName, value } from '@forgerock/backstage-ui-core';
import { EverythingSearchService } from '../everything-search.service';
import { SearchResult } from '../model/search-result';
import { PANEL_ANIMATION } from '../panel-animation';

@Component({
  selector: 'app-dashboard-marketplace-panel',
  templateUrl: './dashboard-marketplace-panel.component.html',
  styleUrls: [ './dashboard-marketplace-panel.component.scss' ],
  animations: [ PANEL_ANIMATION ],
  providers: [ EverythingSearchService ]
})
export class DashboardMarketplacePanelComponent implements OnInit {
  loaded: boolean;
  results: SearchResult[] = [];

  constructor(private searchService: EverythingSearchService) { }

  async ngOnInit() {
    try {
      const {results} = await this.searchService
        .withSort(SearchSortFieldName._score, false)
        .withNestedFilter(
          value('source', SearchDocumentType.MARKETPLACE_ENTRY)
        )
        .withBoosts([
          {
            boost: 100,
            filter: value('tags', 'Featured')
          },
          {
            boost: 10,
            filter: value('tags', 'Supported by: Ping')
          },
          {
            boost: 5,
            filter: value('tags', 'Supported by: Partner')
          }
        ])
        .getPageAsync();
      this.results = results;
      this.loaded = true;
    } catch {
      // ignore
    }
  }

}
