<div class="card h-100" [class]="className || 'bg-gray-300'">
  <div class="card-header {{headerClassName}} h5 m-0">
    <ng-content select="header"></ng-content>
  </div>
  <div class="card-body h-100">
    <ng-content select="main"></ng-content>
  </div>
  <div class="card-footer bg-transparent">
    <ng-content select="footer"></ng-content>
  </div>
  <ng-content></ng-content>
</div>
